<template>
  <div class="flex items-center">
    <router-link :to="`/users/${params.data.id}`" @click.stop.prevent class="text-inherit hover:text-primary">{{ params.value }}</router-link>
  </div>
</template>

<script>
    export default {
      name: 'CellRendererLink',
    }
</script>
