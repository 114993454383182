<template>
  <div class="flex items-center">
    <vs-avatar :src="$store.state.domain+'/'+params.data.image" class="flex-shrink-0 mr-2" size="30px"  @click="$router.push({path: `/users/${params.data.id}`})" />
    <router-link :to="`/users/${params.data.id}`" @click.stop.prevent
    class="text-inherit hover:text-primary">
    {{ params.data.role == 'worker'? params.data.username : params.data.first_name }}</router-link>
  </div>
</template>

<script>
    export default {
      name: 'CellRendererLink',
    }
</script>
