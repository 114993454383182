<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
  export default {
    name: 'CellRendererActions',
    methods: {
      editRecord() {
        this.$router.push(`/users/${this.params.data.id}/edit`).catch(() => {})
      },
      confirmDeleteRecord() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Confirm Delete`,
          text: `You are about to delete "${this.params.data.first_name}"`,
          accept: this.deleteRecord,
          acceptText: "Delete"
        })
      },
      deleteRecord() {
        if(this.$route.params.userId < 0 ) {return}
        this.$store.dispatch("userList/deleteUser", this.params.data.id)
        .then(response => {
          if(response.data.success){
            this.alertSuccess(response.data.success)
          }else{
            this.alertError(response.data.error)
          }
        }).catch(error => { console.log(error) })
      },
    }
  }
</script>
